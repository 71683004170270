import React from "react"
import HeroPacific from "../components/pages/pacific/HeroPacific"
import { Main } from "../components/layout"
import { content } from "../content/pacific"
import MentalHealth from "../components/pages/pacific/MentalHealth"
import LearnMore from "../components/pages/pacific/LearnMore"

const Pacific = () => {
	return (
		<Main>
			<HeroPacific logo={content.hero.logo} text={content.hero.text} img={content.hero.image} />
			<MentalHealth
				title={content.mentalHealth.title}
				text={content.mentalHealth.text}
				image={content.mentalHealth.image}
			/>
			{/* <LearnMore title={content.LearnMore.title} text={content.LearnMore.text} /> */}
		</Main>
	)
}

export default Pacific
