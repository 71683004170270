import React from "react"
import img_hero from "../assets/images/pacific_hero_img.png"
import logo_hero from "../assets/images/pacific_hero_logo.png"
import mental_health_img from "../assets/images/pacific_mental_health.png"

export const content = {
	// Hero Section
	hero: {
		logo: logo_hero,
		text: 
		<>
			Galea and University of the Pacific are excited to team up to provide athletes with holistic, proactive mental health 
			and mental performance support.
			<br/> In the world of college athletics, pressures such as busy schedules, academic stress, 
			social challenges, and performance anxiety as well as common challenges like perfectionism, burnout, OCD, substance use,
			and eating disorders increase the stress that student-athletes experience. Players who suffer a serious injury are two 
			to seven times more likely to report mental health conditions than their peers. Learning how to manage stress and 
			prioritize mental health are crucial aspects of emotional well-being, athletic performance, and academic success. 
			University of the Pacific is committed to supporting student-athletes providing resources for student-athletes so 
			they can excel on and off the field.
		 </>,
		image: img_hero,
	},

	// Mental Health Provider Network
	mentalHealth: {
		title: "Mental Health Provider Network",
		image: mental_health_img,
		text: (
			<>
				Galea vets and curates athlete-aligned therapists and performance coaches in California with expertise ranging
				from clinical mental health to peak performance. Galea also offers personalized referrals for any UPacific
				athletes, coaches, or administrators seeking support from a mental health or mental performance professional.
				<br />
				<br />
				Email
				<span> team@galeahealth.com </span> to receive personalized referral support.
			</>
		),
	},

	// Learn More
	LearnMore: {
		title: "Interested in learning more?",
		text: "E-mail us with questions, concerns, or suggestions.",
	},
}
